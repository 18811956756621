import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

const app = initializeApp({
  apiKey: 'AIzaSyDBI9x0xvXgQpimGJdtJNwQ0pcFZQelVhc',
  authDomain: 'openai-1111.firebaseapp.com',
  projectId: 'openai-1111',
  storageBucket: 'openai-1111.appspot.com',
  messagingSenderId: '161322896136',
  appId: '1:161322896136:web:139fc313c5231aa63ebabd',
  measurementId: 'G-M9ZNJX9GMY'
});

const functions = getFunctions(app);
getAnalytics(app);

const getResponse = async messages => {
  const chatResponse = httpsCallable(functions, 'chatResponse', {
    timeout: 11 * 60 * 1000
  });
  const response = await chatResponse({ messages, model: 'gpt-4' });
  return response;
};

const createImage = async prompt => {
  const generateImage = httpsCallable(functions, 'generateImage', {
    timeout: 11 * 60 * 1000
  });
  const imageUrl = await generateImage({ prompt });
  return imageUrl;
};

const email = async ({ user, messages }) => {
  const transcript = `${messages
    .slice(1)
    .map(
      message =>
        `${
          message.role === 'user'
            ? `<h3>${message.content}</h3>`
            : `<p style="white-space: pre-wrap;">${message.content}</p>`
        }`
    )
    .join('')}`;

  const subject = (
    await getResponse([
      ...messages,
      {
        role: 'user',
        content: 'Create an email subject from this transcript.'
      }
    ])
  ).data.content.replace(/"/g, '');

  const body = `<p>Hello ${user.name}!</p><p>Here is your transcript.</p>${transcript}<p>hugs, Tristan</p>`;

  const sendEmail = httpsCallable(functions, 'email');
  const response = await sendEmail({
    to: user.email,
    subject,
    body
  });
  return { response, subject };
};

export { getResponse, email, createImage };

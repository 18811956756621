import { useState, useRef, useEffect } from 'react';
import {
  ListItem,
  ListItemDecorator,
  IconButton,
  ListItemContent,
  List,
  Box,
  Grid,
  Card,
  Textarea,
  Typography
} from '@mui/joy';
import Lottie from 'lottie-react';
import thinking from '../lottie/thinking-robot.json';
import { fetchSpeechAudio, init } from '../modules/google-speech';
import { getResponse } from '../modules/huggingface';
import { SmartToy, Face, Send } from '@mui/icons-material';
import { useUser } from '../context/user';
import SpeechPlayer from './SpeechPlayer';

const ChatMode = () => {
  const [querying, setQuerying] = useState(false);
  const [question, setQuestion] = useState('');
  const { messages, setMessages, voice, user } = useUser();
  const [speech, setSpeech] = useState();
  const chatRef = useRef();

  useEffect(() => {
    if (chatRef.current) {
      const { scrollHeight, clientHeight } = chatRef.current;
      chatRef.current.scrollTo({
        top: scrollHeight - clientHeight,
        behavior: 'smooth' // Add smooth scrolling behavior
      });
    }
  }, [messages]);

  const messageHistory = () => {
    if (messages.length === 1) {
      return null;
    }
    const formattedMessages = [];

    for (const [i, message] of messages.entries()) {
      if (message.role === 'assistant') {
        formattedMessages.push(
          <ListItem key={`assistant-${i}`}>
            <ListItemDecorator>
              <IconButton
                size="sm"
                variant="plain"
                onClick={async () => {
                  init();
                  const responseAudio = await fetchSpeechAudio({
                    text: message.content,
                    voice
                  });
                  setSpeech(responseAudio);
                }}
              >
                <SmartToy />
              </IconButton>
            </ListItemDecorator>
            <ListItemContent>
              <Typography level="title-sm">Bot</Typography>
            </ListItemContent>
          </ListItem>,
          <ListItem key={`assistantResponse-${i}`}>
            <ListItemDecorator></ListItemDecorator>
            <ListItemContent sx={{ whiteSpace: 'pre-wrap' }}>
              {message.content}
            </ListItemContent>
          </ListItem>
        );
      } else if (message.role === 'user') {
        formattedMessages.push(
          <ListItem key={`you-${i}`}>
            <ListItemDecorator>
              <IconButton size="sm" variant="plain" disabled>
                <Face />
              </IconButton>
            </ListItemDecorator>
            <ListItemContent>
              <Typography level="title-sm">{user?.name}</Typography>
            </ListItemContent>
          </ListItem>,
          <ListItem key={`message-${i}`}>
            <ListItemDecorator></ListItemDecorator>
            <ListItemContent
              sx={{ whiteSpace: 'pre-wrap', fontStyle: 'italic' }}
            >
              {message.content}
            </ListItemContent>
          </ListItem>
        );
      }
    }

    return formattedMessages;
  };

  const submitQuestion = async () => {
    try {
      setQuerying(true);
      const userQuestion = { role: 'user', content: question };
      setQuestion('');
      setMessages([...messages, userQuestion]);

      const response = await getResponse({
        inputs: {
          text: question
        }
      });

      setQuerying(false);
      setMessages([
        ...messages,
        userQuestion,
        { content: response.generated_text, role: 'assistant' }
      ]);
    } catch (err) {
      setMessages(messages);
      alert(
        '🤪 sorry, I was sleeping. 😴 Please try again in about 20 seconds! 🙏'
      );
      console.error(err);
      setQuerying(false);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid lg={7} md={9} xs={12} sm={11}>
        <Box
          height="100vh"
          display="flex"
          flexDirection="column"
          paddingTop={8}
        >
          <Card
            sx={{ flexGrow: 1, overflow: 'auto', scrollBehavior: 'smooth' }}
            ref={chatRef}
          >
            {messages.length > 1 && <List>{messageHistory()}</List>}
            {querying && (
              <Box justifyContent={'center'} display={'flex'}>
                <Lottie animationData={thinking} style={{ width: 111 }} />
              </Box>
            )}
          </Card>

          {speech ? (
            <SpeechPlayer
              completed={() => {
                setSpeech(null);
                console.log('Audio completed.');
              }}
              cancelled={() => {
                setSpeech(null);
                setQuestion('');
              }}
              speech={speech}
            />
          ) : (
            <Box
              justifyContent="space-evenly"
              display="flex"
              width="100%"
              padding={3}
              alignItems="center"
            >
              <Textarea
                placeholder="What's on your mind?"
                size="lg"
                autoFocus
                value={question}
                onChange={event => {
                  if (
                    !(
                      event.target.value.length === 1 &&
                      event.target.value === '\n'
                    )
                  ) {
                    setQuestion(event.target.value);
                  }
                }}
                sx={{ flex: 1, p: 2 }}
                onKeyDown={event => {
                  if (!querying) {
                    if (
                      event.key === 'Enter' &&
                      !event.shiftKey &&
                      question.length > 0
                    ) {
                      submitQuestion();
                    }
                  }
                }}
              />
              <IconButton
                sx={{ ml: 2 }}
                variant="soft"
                disabled={querying}
                size="lg"
                onClick={submitQuestion}
              >
                <Send />
              </IconButton>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChatMode;

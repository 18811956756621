import {
  Button,
  Typography,
  Box,
  Stack,
  Divider,
  IconButton,
  List,
  ListItemDecorator,
  ListItemButton,
  ListItem,
  Alert,
  Drawer,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ModalClose,
  CircularProgress
} from '@mui/joy';
import {
  Menu,
  Settings,
  ChatBubble,
  Delete,
  ForwardToInbox,
  WarningRounded,
  Person2
} from '@mui/icons-material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import funness from '../lottie/robot.json';
import { useState } from 'react';
import { email } from '../modules/firebase';
import { useUser } from '../context/user';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setMessages, messages, user } = useUser();
  const [showDrawer, setShowDrawer] = useState(false);
  const [confirmChatDeletion, setConfirmChatDeletion] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState();

  return (
    <>
      <Stack
        sx={{ position: 'fixed', top: 0, width: '100%', p: 1 }}
        direction="row"
        justifyContent={'space-between'}
      >
        <IconButton size="lg" onClick={() => setShowDrawer(true)}>
          <Menu />
        </IconButton>
        {location.pathname === '/dashboard/chat' && messages.length > 1 && (
          <Stack direction="row" spacing={1} alignItems="center">
            {sendingEmail ? (
              <CircularProgress size="sm" />
            ) : (
              <IconButton
                size="sm"
                onClick={async () => {
                  setSendingEmail(true);
                  const { subject } = await email({ user, messages });
                  console.log(subject);
                  setEmailSent(subject);
                  setSendingEmail(false);
                }}
              >
                <ForwardToInbox />
              </IconButton>
            )}
            <IconButton size="sm" onClick={() => setConfirmChatDeletion(true)}>
              <Delete />
            </IconButton>
          </Stack>
        )}
      </Stack>
      <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Typography level="h1" sx={{ mb: 1, fontFamily: 'Source Sans Pro' }}>
            Bots!
          </Typography>
          <Typography level="h3" sx={{ mb: 1, fontFamily: 'Caveat' }}>
            talk to them
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Alert severity="info" startDecorator={<Person2 />}>
            {user?.name} ({user?.email})
          </Alert>
          <Divider sx={{ my: 2 }} />
          <List sx={{ flexGrow: 1 }}>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  navigate('chat');
                  setShowDrawer(false);
                }}
              >
                <ListItemDecorator>
                  <ChatBubble />
                </ListItemDecorator>
                Chat
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  navigate('settings');
                  setShowDrawer(false);
                }}
              >
                <ListItemDecorator>
                  <Settings />
                </ListItemDecorator>
                Settings
              </ListItemButton>
            </ListItem>
          </List>
          <Lottie animationData={funness} />
          <Alert
            variant="soft"
            endDecorator={
              <Button
                size="sm"
                href="https://golightlyplus.com/contact"
                component="a"
                variant="plain"
                target="_blank"
                color="info"
              >
                Contact
              </Button>
            }
          >
            <Stack>
              <Typography level="body-sm">Bots</Typography>
              <Typography level="body-xs">
                © Golightly+ {new Date().getFullYear()}
              </Typography>
            </Stack>
          </Alert>
        </Box>
      </Drawer>
      <Outlet />
      <Modal
        open={confirmChatDeletion}
        onClose={() => setConfirmChatDeletion(false)}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            Clear chat
          </DialogTitle>
          <Divider />
          <DialogContent>
            Are you sure you want to delete the current chat?
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              onClick={() => {
                setConfirmChatDeletion(false);
                setMessages([]);
              }}
            >
              Delete
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setConfirmChatDeletion(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Modal open={!!emailSent} onClose={() => setEmailSent(null)}>
        <ModalDialog>
          <ModalClose />
          <Typography level="h3" startDecorator={<ForwardToInbox />}>
            Email sent!
          </Typography>
          <Typography level="body-md">
            Check your {user?.email} inbox for an email with the subject "
            {emailSent}".
          </Typography>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default App;

import {
  Button,
  Typography,
  Grid,
  Box,
  Stack,
  Divider,
  Input,
  Card,
  CardContent
} from '@mui/joy';
import { Email, Logout, ManageAccounts, Person2 } from '@mui/icons-material';
import { useUser } from '../context/user';
import { getAuth, signOut } from 'firebase/auth';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';

const SettingsScreen = () => {
  const { user } = useUser();

  return (
    <Grid container justifyContent="center">
      <Grid lg={5} md={6} xs={11} sx={{ mt: 11, mb: 8 }}>
        <Stack spacing={3}>
          <Card variant="outlined">
            <Box display="flex" justifyContent="space-between">
              <Typography
                level="h2"
                fontSize="xl"
                startDecorator={<ManageAccounts />}
              >
                Account details
              </Typography>
            </Box>
            <Divider inset="none" />
            <CardContent>
              <Input
                startDecorator={<Person2 />}
                onChange={event =>
                  updateDoc(doc(getFirestore(), 'users', user.id), {
                    name: event.target.value
                  })
                }
                value={user?.name}
              />
              <Input
                startDecorator={<Email />}
                onChange={event =>
                  updateDoc(doc(getFirestore(), 'users', user.id), {
                    email: event.target.value
                  })
                }
                value={user?.email}
              />
            </CardContent>
          </Card>
          <Button
            variant="plain"
            startDecorator={<Logout />}
            onClick={() => signOut(getAuth())}
          >
            Log out
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SettingsScreen;

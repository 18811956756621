import {
  AspectRatio,
  Typography,
  Button,
  Container,
  Grid,
  Stack,
  Input,
  Divider,
  Modal,
  ModalDialog,
  FormLabel,
  FormControl,
  DialogTitle,
  DialogContent,
  Alert
} from '@mui/joy';
import { useState } from 'react';
import {
  signInWithEmailAndPassword,
  getAuth,
  createUserWithEmailAndPassword
} from 'firebase/auth';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import robot from '../images/cute-robot.jpg';

const LandingPage = () => {
  const [showNewAccountDialog, setShowNewAccountDialog] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Container>
      <Grid
        container
        alignItems="center"
        sx={{
          minHeight: '100vh'
        }}
      >
        <Grid xs={12} lg={6} justifyContent="center" display="flex">
          <Stack spacing={1} alignItems={'center'}>
            <Typography
              level="h1"
              fontSize={55}
              fontFamily="Source Sans Pro"
              fontWeight={900}
            >
              Bots!
            </Typography>
            <Typography
              level="h2"
              fontSize={33}
              textColor="text.secondary"
              fontFamily="Caveat"
            >
              make a new friend
            </Typography>
            <Stack paddingY={3} spacing={3}>
              <Button onClick={() => setShowNewAccountDialog(true)}>
                Register for an account
              </Button>

              <Divider>or</Divider>
              {error && <Alert severity="danger">Ooops, login failed.</Alert>}
              <Typography level="body-sm">
                Already have an account? Login here.
              </Typography>
              <Input
                placeholder="Email"
                size="lg"
                color="primary"
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
              <Input
                placeholder="Password"
                size="lg"
                type="password"
                color="primary"
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
              <Button
                size="lg"
                variant="solid"
                disabled={!email || !password}
                color="primary"
                onClick={() => {
                  signInWithEmailAndPassword(getAuth(), email, password).catch(
                    error => {
                      console.error('error', error);
                      setError(true);
                    }
                  );
                }}
              >
                Login
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid xs={12} lg={6} display={{ xs: 'none', lg: 'block', padding: 11 }}>
          <AspectRatio
            variant="outlined"
            minHeight={555}
            sx={{ borderRadius: 'sm' }}
          >
            <img src={robot} alt="cute robot" />
          </AspectRatio>
        </Grid>
      </Grid>
      <Modal
        open={showNewAccountDialog}
        onClose={() => setShowNewAccountDialog(false)}
      >
        <ModalDialog size="lg">
          <DialogTitle>Welcome!</DialogTitle>
          <DialogContent>We just need a few details.</DialogContent>
          <form
            onSubmit={async event => {
              event.preventDefault();
              const userCredential = await createUserWithEmailAndPassword(
                getAuth(),
                email,
                password
              );

              console.log('created account for', userCredential.user.uid);

              setDoc(doc(getFirestore(), 'users', userCredential.user.uid), {
                name: name.trim(),
                email
              });

              console.log('created account for', name, email);
              setShowNewAccountDialog(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  autoFocus
                  required
                  type="text"
                  onChange={e => setName(e.target.value)}
                  value={name}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Email address</FormLabel>
                <Input
                  required
                  type="email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  required
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                />
              </FormControl>
              <Button type="submit" disabled={!name || !email || !password}>
                Register
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </Container>
  );
};

export default LandingPage;

import ky from 'ky';
import sound from './click.json';

const speech = new Audio();
speech.autoplay = true;

const init = () => (speech.src = `data:audio/wav;base64,${sound.audio}`);

const fetchSpeechAudio = async ({ text }) => {
  console.log({ text });
  try {
    const response = await ky
      .post(
        `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
        {
          json: {
            audioConfig: {
              audioEncoding: 'LINEAR16'
            },
            input: {
              text
            },
            voice: {
              languageCode: 'en-GB',
              name: 'en-GB-Studio-B'
            }
          }
        }
      )
      .json();

    speech.src = `data:audio/wav;base64,${response.audioContent}`;

    return speech;
  } catch (error) {
    console.error(error);
    if (error.name === 'HTTPError') {
      const errorDetails = await error.response.json();
      throw new Error(errorDetails);
    } else {
      throw new Error(error.message);
    }
  }
};

export { init, fetchSpeechAudio };

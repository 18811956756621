import fetch from 'node-fetch';
const getResponse = async data => {
  const response = await fetch(
    'https://api-inference.huggingface.co/models/facebook/blenderbot_small-90M',
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_HUGGING_FACE_API_TOKEN}`
      },
      method: 'POST',
      body: JSON.stringify(data)
    }
  );
  if (response.status !== 200) {
    throw new Error(`Request failed with status code ${response.status}`);
  }
  const result = await response.json();
  return result;
};

// import ky from 'ky';

// const getResponse = async data => {
//   try {
//     console.log({
//       headers: {
//         Authorization: `Bearer ${process.env.REACT_APP_HUGGING_FACE_API_TOKEN}`
//       },
//       json: data
//     });
//     const response = await ky
//       .post(
//         'https://api-inference.huggingface.co/models/facebook/blenderbot_small-90M',
//         {
//           headers: {
//             Authorization: `Bearer ${process.env.HUGGING_FACE_API_TOKEN}`
//           },
//           json: data
//         }
//       )
//       .json();

//     return response;
//   } catch (error) {
//     console.error(`Error in query function: ${error}`);
//   }
// };

export { getResponse };

// query({"inputs": {
// 		"past_user_inputs": ["Which movie is the best ?"],
// 		"generated_responses": ["It is Die Hard for sure."],
// 		"text": "Can you explain why ?"
// 	}}).then((response) => {
// 	console.log(JSON.stringify(response));
// });
